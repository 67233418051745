import './App.css';

function App() {

  const birthday = new Date('2006-09-06');
  const today = new Date();
  const shouldCelebrate = today.getMonth() === birthday.getMonth() && today.getDate() === birthday.getDate();

  return (
    <div className="App">
      <header className="App-header">
        {shouldCelebrate
          ? <>
            <div className='App-logo'>🥳</div>
            <div>Jaaaawohl!</div>
          </>
          : <div>Nopes, nog niet!</div>
        }
      </header>
    </div>
  );
}

export default App;
